import { DataGridProps, TableRowId } from "@fluentui/react-components";
import { account, tournamentparticipant } from "../types/padeltypes";
import { useState } from "react";
import { IRecord } from "@eavfw/manifest";


export function getCommonAccounts(payload: { items: tournamentparticipant[] }): Array<account> {

    const players = payload?.items.map(item => item.player) ?? [];
    if (players.length === 0)
        return [];
    // Extract all account IDs for each player
    const playerAccountIds = players.map(player =>
        player.accountroleassignments.map(assignment => assignment.accountid)
    );
    // Find common account IDs
    const commonAccountIds = playerAccountIds.reduce((common, current) =>
        common.filter(id => current.includes(id))
    );

    // Get full account details for common accounts
    const commonAccounts = players.flatMap(player =>
        player.accountroleassignments
            .filter(assignment => commonAccountIds.includes(assignment.accountid))
            .map(assignment => assignment.account)
    );

    // Remove duplicates
    const uniqueCommonAccounts = Array.from(new Set(commonAccounts.map(a => JSON.stringify(a)))).map(a => JSON.parse(a));

    return uniqueCommonAccounts;
}


export type controlledDataGridSelection = {
    onSelectionChange: DataGridProps["onSelectionChange"],
    selectedItems: Set<string>,
    reset: (initial: string[]) => void
}
export const useControlledDataGridSelection = (initialSelectedRows: Set<TableRowId>) => {
    const [selectedRows, setSelectedRows] = useState(
        initialSelectedRows
    );
    const onSelectionChange: DataGridProps["onSelectionChange"] = (e, data) => {
        setSelectedRows(data.selectedItems);
    };

    return {
        selectedItems: selectedRows, onSelectionChange, reset: (initial: string[]) => { setSelectedRows(new Set<string>(initial)); }
    } as controlledDataGridSelection;
}

export function groupByTeams<T extends IRecord>(items: Array<T>) {
    let teams = {} as { [key: string]: Array<T> };
    for (let item of items) {
        let key = item.team?.id;
        if (!(key in teams))
            teams[key] = [];

        teams[key].push(item);


    }
    console.log("GroupByTeams", teams);
    return teams;// Object.values(teams);
}