import { IRecord } from "@eavfw/manifest";

import { useArrowNavigationGroup, Table, TableHeader, TableRow, TableHeaderCell, TableSelectionCell, TableBody, TableCell, createTableColumn, Label, TableColumnDefinition } from "@fluentui/react-components";
import { PropsWithChildren, useMemo } from "react";
import moment from "moment";
import { useKeyedTableFeatures } from "../utils/useKeyedTable";
import { paymenttype, tournamentparticipant, tournamentparticipantpayment } from "../types/padeltypes";
import { Icon } from "./Icon";



const teamColumns: TableColumnDefinition<IRecord>[] = [
    createTableColumn<IRecord>({
        columnId: 'name',
    }),
    createTableColumn<IRecord>({
        columnId: 'phone',
    }),
    createTableColumn<IRecord>({
        columnId: 'payments',
    }),
    createTableColumn<IRecord>({
        columnId: 'status',
    }),
    createTableColumn<IRecord>({
        columnId: 'count',
    }),
    createTableColumn<IRecord>({
        columnId: 'entrytime',
    }),
    createTableColumn<IRecord>({
        columnId: 'entryreason',
    }),
    createTableColumn<IRecord>({
        columnId: 'teamname',
    }),
    createTableColumn<IRecord>({
        columnId: 'purpose',
    }),
    createTableColumn<IRecord>({
        columnId: 'userstatus',
    }),
    createTableColumn<IRecord>({
        columnId: 'classname',
    }),
    createTableColumn<IRecord>({
        columnId: 'date',
    }),
];

const notMatchedColumns = [
    createTableColumn<IRecord>({
        columnId: 'line',
    }),
    createTableColumn<IRecord>({
        columnId: 'description',
    })
];

function isPaid(t: tournamentparticipantpayment) {
    switch (t.paymenttype) {
        case paymenttype.PaidByOther:
        case paymenttype.PaidByPlayer:
        case paymenttype.PaidByTeam:
            return true;
        default:
            return false;
    }
}
export const TeamsTable: React.FC<PropsWithChildren<{
    setSelectedTeams: any,   
    showAll: boolean,
    defaultStartTime:Date,
    showAllClass: boolean,
    paymentPerParticipant?: { [key: string]: IRecord[] },
    teams: { [key: string]: tournamentparticipant[] }
}>> = ({
    teams, paymentPerParticipant = {}, setSelectedTeams, defaultStartTime ,showAll, showAllClass
}) => {


        const keyboardNavAttr = useArrowNavigationGroup({
            axis: 'grid'
        });

        const items = useMemo(() => Object.entries(teams)

            .map(([teamid, x]) => {

                const paymentsTotal = x.reduce((c, t) => c + t.tournamentparticipantpayments
                    .reduce((sum, p) => sum + (p.tournamententryfee.teamfee ? p.tournamententryfee.amount / 2 : p.tournamententryfee.amount), 0), 0)
                const paymentPaid = x.reduce((c, t) => c + t.tournamentparticipantpayments.filter(isPaid)
                    .reduce((sum, p) => sum + (p.tournamententryfee.teamfee ? p.tournamententryfee.amount / 2 : p.tournamententryfee.amount), 0), 0)

                const paymentDiscountedValue = x.reduce((c, t) => c + t.tournamentparticipantpayments.filter(isPaid)
                    .reduce((sum, p) => sum + (p.tournamententryfee.teamfee ? (p.tournamententryfee.discountamount ?? 0) / 2 : (p.tournamententryfee.discountamount??0) ), 0), 0)

                const transactionAmount = x.reduce((c, t) => c + t.tournamentparticipantpayments.filter(x => x.transaction).reduce((sum, payment) => sum + payment.transaction?.amount ?? 0, 0), 0);
                    
                return ({
                    id: teamid, // x[0].teamid,
                    teamname: x[0].team?.name, // x.map(b => b.user.name).join('/'),//  x[0].team?.name,
                    teamDetails: x.map(b => `${b.name} (${b.player.name})`).reduce((x, l) => x + l, ''),
                    classname: `${x[0].class?.name} (${x[0].class?.type?.name})`,
                    date: moment(x[0].class?.starttime ?? defaultStartTime).format("MMM Do"),
                    payments: `${paymentPaid}/${paymentsTotal}kr rabat:${paymentDiscountedValue}kr`,
                    paymentDetails: `${x.reduce((c, t) => c + t.tournamentparticipantpayments.filter(x => x.transaction).reduce((sum, payment) => sum + payment.tournamententryfee?.name +'\n', ''), '')}`,
                    smscount: 0, // x.reduce((c, t) => c + t.tournamentparticipantpayments.reduce((cc: number, cr: IRecord) => cc + cr.smsmessages.length, 0), 0),
                    
                    classTypeName: x[0].class?.type?.name
                } as IRecord);
            }).filter(x => (showAll || x.amountPayedForTeam !== 600) && (showAllClass || x.classTypeName)), [teams, showAll, showAllClass]);

    console.log("Items", [showAll, items, teams]);

        const { TeamsRows, allTeamsRowsSelected, someTeamsRowsSelected, toggleAllTeamsRows } = useKeyedTableFeatures("Teams", teamColumns,
            items
            , {
                onSelectionChange: (e, data) => {
                    console.log("Selection Change", [Array.from(data.selectedItems), teams]);
                    setSelectedTeams(Array.from(data.selectedItems))
                }
            });

        return (<>
            <Label>Teams</Label>

            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell style={{ width: "20px" }} >#</TableHeaderCell>
                        <TableSelectionCell
                            checked={allTeamsRowsSelected ? true : someTeamsRowsSelected ? 'mixed' : false}
                            onClick={toggleAllTeamsRows}
                        />
                        <TableHeaderCell>Team</TableHeaderCell>
                        <TableHeaderCell>Class</TableHeaderCell>
                        <TableHeaderCell>Payments</TableHeaderCell>
                        <TableHeaderCell>SMS Count</TableHeaderCell>
                        <TableHeaderCell>Date</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody {...keyboardNavAttr}>
                    {TeamsRows.map(({ item, selected, onClick, onKeyDown, appearance, rowId }, n) => (
                        <TableRow
                            key={item.id}
                            onClick={onClick}
                            onKeyDown={onKeyDown}
                            aria-selected={selected}
                            appearance={appearance}
                        >
                            <TableCell style={{ width: "20px" }} width="20px">{n}</TableCell>
                            <TableSelectionCell tabIndex={0} checkboxIndicator={{ tabIndex: -1 }} checked={selected} />
                            <TableCell><Icon label={item.teamname}><div style={{ whiteSpace: "pre-line" }}>{item.teamDetails}</div></Icon></TableCell>
                            <TableCell>{item.classname}</TableCell>
                            <TableCell>
                                <Icon label={item.payments}><div style={{ whiteSpace: "pre-line" }}>{item.paymentDetails}</div></Icon>

                            </TableCell>
                            <TableCell>{item.smscount}</TableCell>
                            <TableCell>{item.date}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table></>
        )

    }