import { RegistereRibbonButton, useAppInfo, useModelDrivenApp, useRibbon, SimpleDialog, useFormChangeHandlerProvider } from "@eavfw/apps";

import { useBoolean } from "@fluentui/react-hooks";
import dynamic from 'next/dynamic'
import { Suspense, useCallback } from 'react'

import { Dropdown, Option } from '@fluentui/react-components';
import { useState } from "react";
import { queryEntitySWR, useJsonFetcher } from "@eavfw/manifest";
import { ConnectProviderComponent } from "../../OAuthContext/AuthorizeAccountRibbonButton";


RegistereRibbonButton("CUSTOM_DINERO_AUTHORIZE", ({ key, ...props }) => {
    const { registerButton, events } = useRibbon();


    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const app = useModelDrivenApp();

    registerButton({
        key: key,
        text: "Connect Dinero",
        iconProps: props.iconProps ?? { iconName: 'Send' },
        title: props.title ?? props.text ?? "Connect Dinero",
        disabled: false,
        onClick: () => {
            toggleHideDialog();

        }
        //workflow: props.workflow
        // onClick: onClick
    }, [toggleHideDialog]);

   

    return <SimpleDialog hideDialog={hideDialog} toggleHideDialog={toggleHideDialog}>

        <ConnectProviderComponent displayName={"Dinero"} provider="OAuthContext.Dinero" />

    </SimpleDialog>

});