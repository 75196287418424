import { RegistereRibbonButton, useAppInfo, useModelDrivenApp, useRibbon, SimpleDialog, useFormChangeHandlerProvider, useModelDrivenViewContext, useSectionStyles } from "@eavfw/apps";

import { useBoolean } from "@fluentui/react-hooks";
import dynamic from 'next/dynamic'
import { Dispatch, SetStateAction, Suspense, useCallback, useEffect, useMemo } from 'react'

import { Divider, Avatar, Button, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridProps, DataGridRow, DialogBody, DialogSurface, Dropdown, Field, Input, Option, ProgressBar, TableCellLayout, TableColumnDefinition, TableRowId, createTableColumn, useId, TableColumnSizingOptions, Textarea, SkeletonItem, Skeleton, Tooltip, Popover, PopoverTrigger, PopoverSurface, Badge } from '@fluentui/react-components';
import { useState } from "react";
import { queryEntitySWR, useJsonFetcher } from "@eavfw/manifest";
import { ODataBuilder } from "@eavfw/query";
import { useEAVForm } from "@eavfw/forms";
import { WorkflowState, runWorkflow } from "@eavfw/utils";
import { Form } from "@rjsf/fluentui-rc"
import validator from "@rjsf/validator-ajv8"



import {
    FolderRegular,
    EditRegular, SaveRegular,
    OpenRegular,
    DocumentRegular,
    DocumentPdfRegular,
    VideoRegular,
    DeleteRegular,
} from "@fluentui/react-icons";




const formatter = new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK'
});

function formatUnit(str: string, unit: any) {
    if (str)
        return `${str}${unit}`
    return str;
}
function formatPrice(cost: number) {
    if (cost)
        return formatter.format(cost);
    return 'Gratis';
}
type MessageTemplate = {
    id: string;
    name: string;
    subject: string;
    template: string;
}
import { InfoRegular } from "@fluentui/react-icons";
import { account, tournamententryfee, tournamentparticipant } from "./types/padeltypes";
import { UserDataGrid } from "./components/ParticipantUserGrid";
import { getCommonAccounts, useControlledDataGridSelection } from "./utils/padelutils";
const TemplatePicker = ({ isWorking, state, emails, defaultTemplate }: { defaultTemplate: string, emails: string[], isWorking: boolean, state: [MessageTemplate | undefined, Dispatch<SetStateAction<MessageTemplate | undefined>>] }) => {
    const app = useModelDrivenApp();
    const { data: templates, isLoading } = queryEntitySWR<MessageTemplate>(app.getEntityFromKey("Message Template"));
    const feeDropdownId = useId("dropdown-templates");
    const [template, setTemplate] = state;
    const styles = useSectionStyles();

    useEffect(() => {
        console.log("template name", template?.name, defaultTemplate);
        if (!template && templates?.items.length > 0) {
            setTemplate(templates.items.find(x => x.name === defaultTemplate))

        }
    }, [template, setTemplate, templates]);

    if (isLoading || !template)
        return <Skeleton><SkeletonItem /></Skeleton>
   
    return (<div style={{ display: "flex", gap: "0.5rem"}}>
        <Dropdown aria-readonly={isWorking} style={{ flexGrow: "1" }}
            value={template.name}
            selectedOptions={[template?.id!]}
            aria-labelledby={feeDropdownId}
            placeholder="Vælg besked template"
            onOptionSelect={(e, data) => setTemplate(templates?.items.find(x => x.id === data.optionValue)!)}

        >
            {templates?.items.map((option) => (
                <Option key={option.id} value={option.id}>
                    {option.name}
                </Option>
            ))}
        </Dropdown>
        <Popover positioning="before" withArrow size="large">
            <PopoverTrigger disableButtonEnhancement>
                <Tooltip content="Click to see message template" relationship="label">
                    <Button icon={<InfoRegular />} />
                </Tooltip>
            </PopoverTrigger>

            <PopoverSurface tabIndex={-1}>
                <Field label="To:">
                    <div className={styles.section} style={{ gap: "0.5rem", display: "flex" }}>
                        {emails.map(x => <Badge key={x} shape="rounded" > {x}  </Badge>)}
                    </div>
                </Field>
                <Field label="Emne:">
                    <div className={styles.section}>
                        {template?.subject}
                    </div>
                </Field>
                <Field label="Besked:">
                    <div className={styles.section} dangerouslySetInnerHTML={{ __html: template?.template.replace(/(?:\r\n|\r|\n)/g, '<br/>') ?? '' }} >

                    </div>
                </Field>
            </PopoverSurface>
        </Popover>

    </div>)
}

RegistereRibbonButton("CUSTOM_APPROVE_TEAM", ({ key, ...props }) => {
    const { registerButton, events } = useRibbon();


    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [isWorking, { toggle: toggleIsWorking }] = useBoolean(false);
    const [{ teamid, tournamentid, tournamentname, status }] = useEAVForm(state => ({ status: state.formValues.status, teamid: state.formValues.teamid, tournamentid: state.formValues.tournamentid, tournamentname: state.formValues.tournament.name }));

    const app = useModelDrivenApp();
    const { currentEntityName, currentRecordId } = useAppInfo();
    const currentEntityCollectionSchemaName = app.getEntity(currentEntityName).collectionSchemaName;

    registerButton({
        key: key,
        text: "Godkend Tilmelding",
        iconProps: props.iconProps ?? { iconName: 'Send' },
        title: props.title ?? props.text ?? "Godkend Tilmelding",
        disabled: status === 40,//approved
        onClick: () => {
            toggleHideDialog();

        }
        //workflow: props.workflow
        // onClick: onClick
    }, [toggleHideDialog, status]);

    const { data: team } = queryEntitySWR<tournamentparticipant>(app.getEntityFromKey("Tournament Participant"),
        new ODataBuilder<tournamentparticipant>().filter(`teamid eq ${teamid}`)
            .select("id")
            .expand("player",
                player => player
                    .select("name")
                    .expandCollection("networkparticipants", networkparticipants => networkparticipants.expand("network", network => network.select("name")))
                    .expandCollection("accountroleassignments", roles => roles
                        .select("accountid")
                        .expand("role", role => role.select("name"))
                        .expand("account",
                            account => account.select("name", "id", "primarycompanycode"))
                    ).expand("primaryemail", contactinfo => contactinfo)
                    .expand("primarymobilephone")
            ).build(), !hideDialog
    );
    const { data: tickets } = queryEntitySWR<tournamententryfee>(app.getEntityFromKey("Tournament Entry Fee"),
        new ODataBuilder<tournamententryfee>().filter(`tournamentid eq ${tournamentid}`).build(),
        !hideDialog
    );

    const accounts = getCommonAccounts(team);
    const accountDropdownId = useId("dropdown-account");
    const feeDropdownId = useId("dropdown-fees");
    const selection = useControlledDataGridSelection(new Set<string>(team?.items.map(k => k.id) ?? []));
    useEffect(() => {
        selection.reset(team?.items.map(k => k.id) ?? []);
    }, [team?.items])

    const { mutate: mutateView } = useModelDrivenViewContext();
    const { mutate: mutateForm } = useFormChangeHandlerProvider();
    const mutate = () => { mutateView(); mutateForm(); }
    const [buttonTxt, setButtonTxt] = useState("Godkend");
    const [statusPayload, setStatusPayload] = useState<WorkflowState>();

    const defaultTicket = useMemo(() => tickets?.items[0], [tickets?.items]);
    const [selectedTicket, setSelectedTicket] = useState(defaultTicket);

    const selectedTicketOrDefault = selectedTicket ?? defaultTicket;

    const [editiableRow, setEditiableRow] = useState<string>();
    const templateState = useState<MessageTemplate>();
    const amount = (selectedTicketOrDefault?.amount ?? 0) - (selectedTicketOrDefault?.discountamount ?? 0);
    useEffect(() => { templateState[1](undefined); }, [amount, selection.selectedItems.size]);

    if (accounts.length === 0 || !selectedTicketOrDefault)
        return null;




  
    console.log("AMOUNT", amount);
    const onokclick = async () => {
        if (buttonTxt === "Luk") {
            mutate();
            toggleHideDialog();

            //TODO - mutate is not implemented properly, dirty fix now
            location.reload();

            return;
        }
        toggleIsWorking();

        let { result, rsp } = await runWorkflow(amount > 0 ? "ApproveTournamentParticipantWorkflow" : "ApproveTournamentParticipantFreebiWorkflow", "Manual",
            {
                dinero_org_id: "394952",
                teamid: teamid,
                tournamentid: tournamentid,
                tournamentname: tournamentname,
                entryfeeid: selectedTicketOrDefault.id,
                templateid: templateState[0]?.id,
                amount: amount,
                invoice_account: {
                    id: accounts[0].id,
                    name: accounts[0].name,
                    cvr: accounts[0].primarycompanycode,
                    email: team.items[0].player.primaryemail.value,

                },
                //  ...payload,

                participants: Array.from(selection.selectedItems).map(x => ({ id: x, ["$type"]: "tournamentparticipants" }))
            },
            {
                currentEntityCollectionSchemaName, currentRecordId, onStatusUpdated: (a) => {
                    console.log("Status Updated: ", a);
                    setStatusPayload(a);
                }, fullStatusPayload: true, refreshInterval: 1000
            });


        if (result.status.toLowerCase() === "failed") {

            return;
        }

        setButtonTxt("Luk");
        toggleIsWorking();


    }



    return (<>

        <SimpleDialog primaryButton={{ disabled: isWorking, children: buttonTxt, onClick: onokclick }} title={`${tournamentname} - Godkend Tilmeldelse`} hideDialog={hideDialog} toggleHideDialog={toggleHideDialog}>

            <div style={{ display: "flex", gap: "1rem", flexDirection: "column" }}>

                <Field aria-readonly={isWorking}
                    label="Faktura sendes til:"
                    validationState={accounts.length > 1 ? "warning" : "none"}
                    validationMessage={accounts.length > 1 ? "OBS - der er flere" : undefined}

                >
                    <Dropdown aria-readonly={isWorking} defaultValue={`${accounts[0].name} (${accounts[0].primarycompanycode})`}
                        aria-labelledby={accountDropdownId}
                        placeholder="Select an animal"

                    >
                        {accounts.map((option) => (
                            <Option key={option.id}>
                                {`${option.name} (${option.primarycompanycode})`}
                            </Option>
                        ))}
                    </Dropdown>
                </Field>

                <Field aria-readonly={isWorking}
                    label={`Pris: ${formatPrice(amount)}`}


                >
                    <Dropdown aria-readonly={isWorking}
                        defaultValue={`${defaultTicket?.name} (${formatPrice(defaultTicket?.amount - (defaultTicket?.discountamount ?? 0))}) - (${formatUnit(defaultTicket?.totalquantity ?? '∞', ' stk')})`}
                        aria-labelledby={feeDropdownId}
                        placeholder="Vælg pris"
                        onOptionSelect={(e, data) => setSelectedTicket(tickets?.items.find(x => x.id === data.optionValue)!)}

                    >
                        {tickets?.items.map((option) => (
                            <Option key={option.id} value={option.id}>
                                {`${option.name} (${formatPrice(option.amount - (option.discountamount ?? 0))}) - (${formatUnit(option.totalquantity ?? '∞', ' stk')})`}
                            </Option>
                        ))}
                    </Dropdown>
                </Field>

                <Field aria-readonly={isWorking}
                    label={`Besked Template:`}


                >
                    <TemplatePicker key={selectedTicketOrDefault.id}
                        defaultTemplate={(amount > 0 ?
                            "Tournament Approved" :
                            "Tournament Approved And Paid"
                        )+(selection.selectedItems.size === 1 ? " (Singular)":"")} emails={team.items.filter(x => selection.selectedItems.has(x.id)).map(x => x.player.primaryemail.value)} isWorking={isWorking} state={templateState} />
                </Field>


                <Field aria-readonly={isWorking}
                    label={`Hold:`}


                >
                    <UserDataGrid isWorking={isWorking} items={team?.items} selection={selection} setEditiableRow={setEditiableRow} />
                </Field>
                {/*<Field aria-readonly={isWorking}*/}
                {/*    label={`Besked:`}*/}


                {/*>*/}
                {/*    <Textarea rows={15} aria-readonly={isWorking} defaultValue="hej" value={message} onChange={(e, data) => setMessage(data.value)} />*/}
                {/*</Field>*/}

                {(isWorking || statusPayload?.status === "Succeded") && <ul>
                    {statusPayload?.events?.filter(x => x.eventType === "action_completed").map(evt => <li>{evt.actionKey} {evt.status === "Failed" ? '❌' : '(✔)'} </li>)}
                </ul>}

                {statusPayload?.failedReason && <pre>{statusPayload?.failedReason}</pre>}

                {isWorking && <ProgressBar />}
            </div>

        </SimpleDialog>
    </>
    );
});