import { IRecord } from "@eavfw/manifest"
import { TableFeaturesState, TableRowData, TableSelectionState, TableColumnDefinition, SelectionHookParams, useTableFeatures, useTableSelection } from "@fluentui/react-components"

type Actions = {
    getRows: TableFeaturesState<IRecord>["getRows"],
    rows: Array<TableRowData<IRecord> & { item: IRecord, onClick: any, onKeyDown: any, selected: boolean, appearance: any }>
} & TableSelectionState

type ActionsMap<T extends string> = {
    rows: `${T}Rows`,
    getRows: `get${T}Rows`,
    isRowSelected: `is${T}RowSelected`,
    toggleRow: `toggle${T}Row`,
    allRowsSelected: `all${T}RowsSelected`,
    clearRows: `clear${T}Rows`,
    deselectRow: `deselect${T}Row`,
    selectedRows: `selected${T}Rows`,
    selectionMode: `${T}selectionMode`,
    selectRow: `select${T}Row`,
    someRowsSelected: `some${T}RowsSelected`,
    toggleAllRows: `toggleAll${T}Rows`
}
type KeyedTableFeatures<T extends string> = { [K in keyof ActionsMap<T> as ActionsMap<T>[K]]: Actions[K]; }

export const useKeyedTableFeatures = <Key extends string, T extends IRecord>(key: Key, columns: TableColumnDefinition<T>[], items: T[], options: Partial<SelectionHookParams> = {}) => {

    const {
        getRows,
        selection: { isRowSelected, toggleRow, allRowsSelected, clearRows, deselectRow, selectedRows, selectionMode, selectRow, someRowsSelected, toggleAllRows }
    } = useTableFeatures({
        columns: columns,
        items: items,
        getRowId: (item) => item.id
    }, [
        useTableSelection({
            selectionMode: 'multiselect',
            onSelectionChange: options.onSelectionChange,
            defaultSelectedItems: new Set([]),
        })
    ]);


    const enchancedRows = getRows((row) => {
        const selected = isRowSelected(row.rowId);
        return {
            ...row,
            onClick: (e: React.MouseEvent) => toggleRow(e, row.rowId),
            onKeyDown: (e: React.KeyboardEvent) => {
                if (e.key === ' ' || e.key === 'Enter') {
                    toggleRow(e, row.rowId);
                }
            },
            selected,
            appearance: selected ? ('brand' as const) : ('none' as const)
        };
    });

    return {
        [`${key}Rows`]: enchancedRows,
        [`get${key}Rows`]: getRows,
        [`is${key}RowSelected`]: isRowSelected,
        [`toggle${key}Row`]: toggleRow,
        [`all${key}RowsSelected`]: allRowsSelected,
        [`clear${key}Rows`]: clearRows,
        [`deselect${key}Row`]: deselectRow,
        [`${key}SelectionMode`]: selectionMode,
        [`selected${key}Rows`]: selectedRows,
        [`select${key}Row`]: selectRow,
        [`some${key}RowsSelected`]: someRowsSelected,
        [`toggleAll${key}Rows`]: toggleAllRows
    } as KeyedTableFeatures<Key>
};
