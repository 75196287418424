import { RegistereRibbonButton, useAppInfo, useModelDrivenApp, useRibbon, SimpleDialog, useFormChangeHandlerProvider } from "@eavfw/apps";

import { useBoolean } from "@fluentui/react-hooks";
import dynamic from 'next/dynamic'
import { Suspense, useCallback } from 'react'

import { Button, Dropdown, Option } from '@fluentui/react-components';
import { useState } from "react";
import { queryEntitySWR, useJsonFetcher } from "@eavfw/manifest";

RegistereRibbonButton("CUSTOM_RUN_WORKFLOWS_QUICKFORM_ANSWERS", ({ key, ...props }) => {
    const { registerButton, events } = useRibbon();


    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const app = useModelDrivenApp();
    const info = useAppInfo();

    registerButton({
        key: key,
        text: "Reprocess",
        iconProps: props.iconProps ?? { iconName: 'Send' },
        title: props.title ?? props.text ?? "Reprocess",
        disabled: false,
        onClick: () => {
            toggleHideDialog();

        }
        //workflow: props.workflow
        // onClick: onClick
    }, [toggleHideDialog]);


    let onClick = () => {
        fetch(`${process.env['NEXT_PUBLIC_API_BASE_URL']}/providers/quickform/answers/${info.currentRecordId}/workflow/run`, {
            method: "POST",
            body: JSON.stringify({}),
        })
    }

    return <SimpleDialog hideDialog={hideDialog} toggleHideDialog={toggleHideDialog}>


        <Button onClick={onClick}>Reprocess</Button>
       
    </SimpleDialog>

});