import { Avatar, Button, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridRow, Divider, Input, TableCellLayout, TableColumnDefinition, TableColumnSizingOptions, createTableColumn } from "@fluentui/react-components";
import { tournamentparticipant } from "../types/padeltypes";
import { controlledDataGridSelection } from "../utils/padelutils";
import { useMemo, useState } from "react";
import { useSectionStyles } from "@eavfw/apps";
import {
    FolderRegular,
    EditRegular, SaveRegular,
    OpenRegular,
    DocumentRegular,
    DocumentPdfRegular,
    VideoRegular,
    DeleteRegular,
} from "@fluentui/react-icons";
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8"



const columnSizingOptions: TableColumnSizingOptions = {
    name: {
        defaultWidth: 200,
        idealWidth: 300
    },

    edit: {
        defaultWidth: 50,
        idealWidth: 50
    }
};



export const UserDataGrid = (
    { items, selection, isWorking, setEditiableRow }:
        { items: tournamentparticipant[], selection: controlledDataGridSelection, isWorking: boolean, setEditiableRow: (id: string) => void }) => {
    const { section } = useSectionStyles();
    const [editableItems, setEditableItems] = useState(new Set<string>());

    const columns: TableColumnDefinition<tournamentparticipant>[] = useMemo(() => [
        createTableColumn<tournamentparticipant>({
            columnId: "name",
            compare: (a, b) => {
                return a.player.name.localeCompare(b.player.name);
            },
            renderHeaderCell: () => {
                return "Navn";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout truncate media={
                        <Avatar
                            aria-label={item.player.name}
                            name={item.player.name}
                            badge={{ status: "available" }}
                        />
                    }>
                        {editableItems.has(item.id) ? <Input value={item.player.name} /> : item.player.name}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<tournamentparticipant>({
            columnId: "email",
            compare: (a, b) => {
                return a.player?.primaryemail?.value.localeCompare(b.player?.primaryemail?.value);
            },
            renderHeaderCell: () => {
                return "Email";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout truncate
                    >
                        {item.player.primaryemail?.value}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<tournamentparticipant>({
            columnId: "mobile",
            compare: (a, b) => {
                return a.player?.primarymobilephone?.value.localeCompare(b.player?.primarymobilephone?.value);
            },
            renderHeaderCell: () => {
                return "Telefonnr";
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout truncate
                    >
                        {item.player.primarymobilephone?.value}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<tournamentparticipant>({
            columnId: "role",
            //compare: (a, b) => {
            //    return a.lastUpdated.timestamp - b.lastUpdated.timestamp;
            //},
            renderHeaderCell: () => {
                return "Title";
            },

            renderCell: (item) => {

                return <TableCellLayout truncate
                > {item.player.accountroleassignments.map(role => role?.role?.name).join(", ")} </TableCellLayout>
            },
        }),
        createTableColumn<tournamentparticipant>({
            columnId: "networks",
            //compare: (a, b) => {
            //    return a.lastUpdated.timestamp - b.lastUpdated.timestamp;
            //},
            renderHeaderCell: () => {
                return "Netværk";
            },

            renderCell: (item) => {
                return <TableCellLayout truncate
                > {item.player.networkparticipants?.map(role => role?.network?.name).join(", ")} </TableCellLayout>
            },
        }),
        createTableColumn<tournamentparticipant>({
            columnId: "edit",
            //compare: (a, b) => {
            //    return a.lastUpdated.timestamp - b.lastUpdated.timestamp;
            //},
            //renderHeaderCell: () => {
            //    return "Netværk";
            //},

            renderCell: (item) => {
                return <TableCellLayout truncate
                >  <Button aria-label="Edit" icon={<EditRegular />} onClick={(e) => { setEditableItems(old => { old.add(item.id); return new Set(old) }); e.preventDefault(); e.stopPropagation(); }} />
                </TableCellLayout>
            },
        })
    ], [editableItems]);
    console.log("editableItems", editableItems);



    return (<DataGrid aria-readonly={isWorking} {...selection}
        items={items??[]}
        columns={columns}
        resizableColumns columnSizingOptions={columnSizingOptions} containerWidthOffset={44}
        sortable
        selectionMode={isWorking ? undefined : "multiselect"}
        getRowId={(item) => item.id}
        focusMode="composite"
        selectionAppearance="brand" width="100%"
    >
        <DataGridHeader>
            <DataGridRow
                selectionCell={{
                    checkboxIndicator: { "aria-label": "Select all rows" },
                    invisible: isWorking
                }}
            >
                {({ renderHeaderCell }) => (
                    <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                )}
            </DataGridRow>
        </DataGridHeader>
        <DataGridBody<tournamentparticipant>>
            {({ item, rowId }) => (
                editableItems.has(item.id) ? <><Form tagName="div" className={section} formData={{
                    name: item.player.name,
                    email: item.player.primaryemail.value,
                    mobile: item.player.primarymobilephone.value,
                    title: item.player.accountroleassignments[0]?.role.name
                }} validator={validator} schema={{
                    type: "object",
                    properties: {
                        "name": { type: "string", title: "Navn" },
                        "email": { type: "string", title: "Email" },
                        "mobile": { type: "string", title: "Mobilenummer" },

                        "title": { type: "string", title: "Title" },
                    }
                }} onSubmit={(data, event) => {
                    alert("a");
                    setEditableItems(old => { old.delete(item.id); return new Set(old) });
                }} >

                    <div style={{ width: "100%", justifyContent: "end", display: "flex" }}><Button aria-label="Submit" appearance="primary" onClick={(e) => { setEditableItems(old => { old.delete(item.id); return new Set(old) }); e.preventDefault(); e.stopPropagation() }} >Gem</Button></div>

                </Form>
                    <Divider />
                </>
                    :
                    <DataGridRow<tournamentparticipant>

                        key={rowId}
                        selectionCell={{
                            checkboxIndicator: { "aria-label": "Select row" },
                            invisible: isWorking || editableItems.has(item.id),
                            //  subtle: true
                        }}
                    >
                        {({ renderCell, columnId }) => (
                            <DataGridCell >{renderCell(item)}</DataGridCell>
                        )}
                    </DataGridRow>
            )}
        </DataGridBody>
    </DataGrid>)
}