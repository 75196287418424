
export type contactinformation = {
    value: any;

}
export type network = {
    name: string;
}
export type networkparticipant = {
    id: string;
    network: network
}
export type contact = {
    name: any;
    primaryemail: contactinformation
    primarymobilephone: contactinformation
    accountroleassignments: accountroleassignment[]
    networkparticipants: networkparticipant[]
}
export type role = {
    id: string;
    name: string;
}
export type account = {
    id: string;
    name: string;
    primarycompanycode: string;
}
export type accountroleassignment = {
    account: account;
    accountid: string;
    contact: contact;
    role: role;
}
export type tournamentteam = {
    id: string;
    name: string;
}
export type tournamentclasstype = {
    name: string;
    id: string;
}
export type tournamentclass = {
    id: string;
    name: string;
    typeid: string;
    type: tournamentclasstype;
    starttime: string | Date;
}
export type tournamentparticipant = {
    id: string;
    name: string;
    classid: string;
    class: tournamentclass;
    player: contact;
    playerid: string;
    teamid: string;
    team: tournamentteam;
    tournamentparticipantpayments: tournamentparticipantpayment[]
}
export enum paymenttype {
    "PaidByPlayer"=0,
    "PaidByTeam" = 1,
    "PaidByOther" = 2,
    "Pending" = 400
}
export type tournamentparticipantpayment = {
    id: string;
    paymenttype: paymenttype; 
    tournamententryfee: tournamententryfee;
    tournamententryfeeid: string;
    transaction: transaction;
    transactionid: string;
}
export type transactioncontactreference = {
    transactionid: string;
    contactid: string;
    contact: contact;
}
export type transactionaccountreference = {
    transactionid: string;
    accountid: string;
    account: account;
}
export type transaction = {
    id: string;
    name: string;
    amount: number;
    description: string;
    shortidentifier: string;
    transactioncontactreferences: transactioncontactreference[]
    transactionaccountreferences: transactionaccountreference[]
}
export type tournamententryfee = {
    totalquantity: any;
    discountamount: number;
    id: string;
    tournamentid: string;
    name: string;
    amount: number;
    teamfee: boolean;
}
export type TournamentParticipantPurpose = {     
    id: string;
    purpose: string;
}