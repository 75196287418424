
export * from "@eavfw/codeeditor";
import { RegisterFeature } from "@eavfw/apps";
import "./Ribbons";
export * from "./CustomControlDemo";

import { PageDesignEditor } from "@eavfw/designer";
import { RegistereControl } from "@eavfw/apps";
RegistereControl("PageDesignEditor", PageDesignEditor);

import "@eavfw/task-management";
import "@eavfw/quickform-designer";
import "@eavfw/quickform-input-select";
import "@eavfw/quickform-input-select/src/designer";
import "@eavfw/quickform-core/src/components/question/input-types";


import "mc-portal/src/components/Communication/SMSCommunicationOverview";

RegisterFeature("WizardExpressionsProvider", (formValues: any) => {

    return {

    }
});