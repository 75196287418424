import { Label, Tooltip, TooltipProps, makeStyles, mergeClasses, tokens } from "@fluentui/react-components";
import { useId } from "@fluentui/react-hooks";
import { useState } from "react";
import { EditRegular, Info16Regular, PlugDisconnected24Regular } from "@fluentui/react-icons";
const useStyles = makeStyles({
    root: {
        display: "flex",
        columnGap: tokens.spacingVerticalS,
    },
    visible: {
        color: tokens.colorNeutralForeground2BrandSelected,
    },
});

export const Icon: React.FC<Partial<TooltipProps> & { label: string }> = ({ label, children, ...props }) => {
    const styles = useStyles();
    const contentId = useId("content");
    const [visible, setVisible] = useState(false);

    return (
        <div aria-owns={visible ? contentId : undefined} className={styles.root}>
            <Label>{label}</Label>
            <Tooltip
                content={{
                    children: children as React.ReactNode,
                    id: contentId,
                }}
                positioning="above-start"
                withArrow
                relationship="label"
                onVisibleChange={(e, data) => setVisible(data.visible)}
                {...props}
            >
                <Info16Regular
                    tabIndex={0}
                    className={mergeClasses(visible && styles.visible)}
                />
            </Tooltip>
        </div>
    );
};