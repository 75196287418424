import { RegistereRibbonButton, useAppInfo, useModelDrivenApp, useRibbon, SimpleDialog, useFormChangeHandlerProvider, useModelDrivenViewContext, useSectionStyles } from "@eavfw/apps";

import { useBoolean } from "@fluentui/react-hooks";
import dynamic from 'next/dynamic'
import { Dispatch, SetStateAction, Suspense, useCallback, useEffect, useMemo } from 'react'

import { Divider, Avatar, Button, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridProps, DataGridRow, DialogBody, DialogSurface, Dropdown, Field, Input, Option, ProgressBar, TableCellLayout, TableColumnDefinition, TableRowId, createTableColumn, useId, TableColumnSizingOptions, Textarea, SkeletonItem, Skeleton, Tooltip, Popover, PopoverTrigger, PopoverSurface, Badge } from '@fluentui/react-components';
import { useState } from "react";
import { queryEntitySWR, useJsonFetcher } from "@eavfw/manifest";
import { ODataBuilder } from "@eavfw/query";
import { useEAVForm } from "@eavfw/forms";
import { WorkflowState, runWorkflow } from "@eavfw/utils";
import { Form } from "@rjsf/fluentui-rc"
import validator from "@rjsf/validator-ajv8"
import { TournamentParticipantPurpose, tournamententryfee, tournamentparticipant } from "./types/padeltypes";
import { getCommonAccounts, useControlledDataGridSelection } from "./utils/padelutils";
import { UserDataGrid } from "./components/ParticipantUserGrid";


RegistereRibbonButton("CUSTOM_SEND_PARTICIPANT_MESSAGE", ({ key, ...props }) => {
    const { registerButton, events } = useRibbon();


    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [isWorking, { toggle: toggleIsWorking }] = useBoolean(false);
    const [{ tournamentid, tournamentname }] = useEAVForm(state => ({
        tournamentid: state.formValues.id,
        tournamentname: state.formValues.name
    }));

    const app = useModelDrivenApp();
    const { currentEntityName, currentRecordId } = useAppInfo();
    const currentEntityCollectionSchemaName = app.getEntity(currentEntityName).collectionSchemaName;

    registerButton({
        key: key,
        text: "Send Besked",
        iconProps: props.iconProps ?? { iconName: 'Send' },
        title: props.title ?? props.text ?? "Send Besked",
       // disabled: status === 40,//approved
        onClick: () => {
            toggleHideDialog();

        }
        //workflow: props.workflow
        // onClick: onClick
    }, [toggleHideDialog, status]);

    const [selectedPurpose, setSelectedPurpose] = useState<string[]>();
    const { data: participants } = queryEntitySWR<tournamentparticipant>(app.getEntityFromKey("Tournament Participant"),
        new ODataBuilder<tournamentparticipant>()
            .filter(`tournamentid eq ${tournamentid}` + (selectedPurpose?.length ?? 0 > 0 ? ` and PurposeId in (${selectedPurpose?.join(',')})` : ``))
            .select("id","playerid")
            .expand("player",
                player => player
                    .select("name")
                    .expandCollection("networkparticipants", networkparticipants => networkparticipants.expand("network", network => network.select("name")))
                    .expandCollection("accountroleassignments", roles => roles
                        .select("accountid")
                        .expand("role", role => role.select("name"))
                        .expand("account",
                            account => account.select("name", "id", "primarycompanycode"))
                    ).expand("primaryemail", contactinfo => contactinfo)
                    .expand("primarymobilephone")
            ).build(), !hideDialog
    );
    const { data: purposes } = queryEntitySWR<TournamentParticipantPurpose>(app.getEntityFromKey("Tournament Participant Purpose"),
        new ODataBuilder<TournamentParticipantPurpose>().build(),
        !hideDialog
    );

  //  const accounts = getCommonAccounts(participants);
    const accountDropdownId = useId("dropdown-account");
    //  const feeDropdownId = useId("dropdown-fees");
    const selection = useControlledDataGridSelection(new Set<string>(participants?.items.map(k => k.id) ?? []));
    useEffect(() => {
        selection.reset(participants?.items.map(k => k.id) ?? []);
    }, [participants?.items])

    const { mutate: mutateView } = useModelDrivenViewContext();
    const { mutate: mutateForm } = useFormChangeHandlerProvider();
    const mutate = () => { mutateView(); mutateForm(); }
    const [buttonTxt, setButtonTxt] = useState("Godkend");
    const [statusPayload, setStatusPayload] = useState<WorkflowState>();

   
   
    const [payload, setPayload] = useState({subject:'', message:''});

    const [editiableRow, setEditiableRow] = useState<string>();

    const onokclick = async () => {
        if (buttonTxt === "Luk") {
            mutate();
            toggleHideDialog();

            //TODO - mutate is not implemented properly, dirty fix now
            location.reload();

            return;
        }
        toggleIsWorking();

        let { result, rsp } = await runWorkflow( "SendPadelParticipantMessageWorkflow", "Manual",
            {
                ...payload,
                tournamentid: tournamentid,
                sender: 'poul@danskpadelsport.dk',
                senderDisplayName: "Poul Kjeldager (Dansk Padel Sport)",
                participants: Array.from(selection.selectedItems).map(x => ({
                    participantid: x,
                    playerid: participants.items.find(p => p.id === x)?.playerid
                }))
            },
            {
                currentEntityCollectionSchemaName, currentRecordId, onStatusUpdated: (a) => {
                    console.log("Status Updated: ", a);
                    setStatusPayload(a);
                }, fullStatusPayload: true, refreshInterval: 1000
            });


        if (result.status.toLowerCase() === "failed") {

            return;
        }

        setButtonTxt("Luk");
        toggleIsWorking();


    }



    return (<>

        <SimpleDialog primaryButton={{ disabled: isWorking, children: buttonTxt, onClick: onokclick }} title={`${tournamentname} - Send Besked`} hideDialog={hideDialog} toggleHideDialog={toggleHideDialog}>

            <div style={{ display: "flex", gap: "1rem", flexDirection: "column" }}>

                <Field aria-readonly={isWorking}
                    label="Filter:"
                    

                >
                    <Dropdown multiselect aria-readonly={isWorking}
                        aria-labelledby={accountDropdownId}
                        placeholder="Filter spillere" onOptionSelect={(e, d) => setSelectedPurpose(old => [...old ?? [], d.optionValue!])}

                    >
                        {purposes?.items.map((purpose) => (
                            <Option key={purpose.id} value={purpose.id}>
                                {`${purpose.purpose}`}
                            </Option>
                        ))}
                    </Dropdown>
                </Field>

                <Field aria-readonly={isWorking}
                    label="Emne:"
                  

                >
                    <Input value={payload.subject} onChange={(e, d) => setPayload(old => ({ ...old, subject: d.value }))} />

                </Field>
                <Field aria-readonly={isWorking}
                    label="Besked:"


                >
                    <Textarea rows={10} value={payload.message} onChange={(e, d) => setPayload(old => ({ ...old, message: d.value }))} />
                </Field>

                <Field aria-readonly={isWorking}
                    label={`Modtagere:`}


                >
                    <UserDataGrid isWorking={isWorking} items={participants?.items} selection={selection} setEditiableRow={setEditiableRow} />
                </Field>

                {(isWorking || statusPayload?.status === "Succeded") && <ul>
                    {statusPayload?.events?.filter(x => x.eventType === "action_completed").map(evt => <li>{evt.actionKey} {evt.status === "Failed" ? '❌' : '(✔)'} </li>)}
                </ul>}

                {statusPayload?.failedReason && <pre>{statusPayload?.failedReason}</pre>}

                {isWorking && <ProgressBar />}
            </div>

        </SimpleDialog>
    </>
    );
});