import { RegistereRibbonButton, useAppInfo, useModelDrivenApp, useRibbon, SimpleDialog, useFormChangeHandlerProvider } from "@eavfw/apps";

import { useBoolean } from "@fluentui/react-hooks";
import dynamic from 'next/dynamic'
import { Suspense, useCallback } from 'react'

import { Dropdown, Option } from '@fluentui/react-components';
import { useState } from "react";
import { queryEntitySWR, useJsonFetcher } from "@eavfw/manifest";
import { CompoundButton, Input, Label, makeStyles, shorthands, useId } from "@fluentui/react-components";
import { PlugDisconnected24Regular } from "@fluentui/react-icons";
import { publicDecrypt } from "crypto";


const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('20px'),
        maxWidth: '400px',
        // Stack the label above the field (with 2px gap per the design system)
        '> div': {
            display: 'flex',
            flexDirection: 'column',
            ...shorthands.gap('2px')
        }
    }
});

//const AiiaConnectComponent: React.FC = () => {

//    const clientidid = useId('input-clientid');
//    const secretid = useId('input-secret');
//    const [clientid, setClientId] = useState<string>();
//    const [secret, setSecret] = useState<string>();
//    const { mutate: mutateForm } = useFormChangeHandlerProvider();
//    const [baseUrl] = useJsonFetcher();
//    const { currentEntityName, currentRecordId } = useAppInfo();
//    const connect = useCallback(() => {
//        fetch(`${baseUrl}/providers/PaymentProviders.Aiia/connect?accountid=${currentRecordId}`, {
//            credentials: "include",
//            method: "POST",
//            headers: {"contentType":"application/json" },
//            body: JSON.stringify({ secret, clientid })
//        })
//            .then(() => {
//                setTimeout(() => {
//                    mutateForm();
//                }, 2500);
//            });
//    }, [secret, clientid]);

//    const styles = useStyles();

//    return <div className={styles.root}>
//        <div>
//            <Label htmlFor={clientidid}>ClientId</Label>
//            <Input type="text" id={clientidid} onChange={(ev, data) => setClientId(data.value)} />
//        </div>
//        <div>
//            <Label htmlFor={secretid}>Password input</Label>
//            <Input type="password"   id={secretid} onChange={(ev, data) => setSecret(data.value)} />
//        </div>

//        <CompoundButton
//            icon={<PlugDisconnected24Regular />}
//            secondaryContent="Connect Aiia" onClick={connect}
//        >
//            Connect
//        </CompoundButton>
//    </div>
//}

export type ConnectProviderComponentProps = {
    provider: string
    displayName: string
}
export const ConnectProviderComponent: React.FC<ConnectProviderComponentProps> = ({ provider, displayName }) => {
    const { currentEntityName, currentRecordId } = useAppInfo();
    const [environment, setEnvironment] = useState("sandbox");
    const [baseUrl] = useJsonFetcher();

    const connect = useCallback(() => {
        window.open(`${baseUrl}/providers/${provider}/connect?accountid=${currentRecordId}&redirecturi=${encodeURIComponent(location.protocol + "//" + location.host + location.pathname + location.search)}&environment=${environment}`, '_blank');
    }, [provider])



    return <>
        <Dropdown placeholder="Environment..." onOptionSelect={(e: any, data: any) => { console.log(data); console.log(e); if (data.optionValue) { setEnvironment(data.optionValue); } }} defaultValue={environment} >
            <Option>Sandbox</Option>
            <Option>Production</Option>
        </Dropdown>



        <CompoundButton
            icon={<PlugDisconnected24Regular />}
            secondaryContent={`Connect ${displayName}`} onClick={connect}
        >
            Connect
        </CompoundButton>
    </>
}
