import { RegistereRibbonButton, useAppInfo, useModelDrivenApp, useRibbon, SimpleDialog, useFormChangeHandlerProvider, useModelDrivenViewContext, useSectionStyles } from "@eavfw/apps";

import { useBoolean } from "@fluentui/react-hooks";
import dynamic from 'next/dynamic'
import { Dispatch, SetStateAction, Suspense, useCallback, useEffect, useMemo } from 'react'

import { Divider, Avatar, Button, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridProps, DataGridRow, DialogBody, DialogSurface, Dropdown, Field, Input, Option, ProgressBar, TableCellLayout, TableColumnDefinition, TableRowId, createTableColumn, useId, TableColumnSizingOptions, Textarea, SkeletonItem, Skeleton, Tooltip, Popover, PopoverTrigger, PopoverSurface, Badge } from '@fluentui/react-components';
import { useState } from "react";
import { IRecord, queryEntitySWR, useJsonFetcher } from "@eavfw/manifest";
import { ODataBuilder } from "@eavfw/query";
import { useEAVForm } from "@eavfw/forms";
import { WorkflowState, runWorkflow } from "@eavfw/utils";
import { Form } from "@rjsf/fluentui-rc"
import validator from "@rjsf/validator-ajv8"
import { TournamentParticipantPurpose, tournamententryfee, tournamentparticipant } from "./types/padeltypes";
import { getCommonAccounts, groupByTeams, useControlledDataGridSelection } from "./utils/padelutils";
import { UserDataGrid } from "./components/ParticipantUserGrid";
import { TeamsTable } from "./components/TeamsTable";


RegistereRibbonButton("CUSTOM_PAYMENT_OVERVIEW", ({ key, ...props }) => {
    const { registerButton, events } = useRibbon();


    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [isWorking, { toggle: toggleIsWorking }] = useBoolean(false);
    const [{ tournamentid, tournamentname, tournamentstarttime }] = useEAVForm(state => ({
        tournamentid: state.formValues.id,
        tournamentname: state.formValues.name,
        tournamentstarttime: state.formValues.starttime,
    }));

    const app = useModelDrivenApp();
    const { currentEntityName, currentRecordId } = useAppInfo();
    const currentEntityCollectionSchemaName = app.getEntity(currentEntityName).collectionSchemaName;

    const [showAll, setShowAll] = useState<boolean>(true);
    const [showAllClass, setShowAllClass] = useState<boolean>(true);
    const [selectedTeams, setSelectedTeams] = useState<string[]>([]);

    registerButton({
        key: key,
        text: "Betalingsoverblik",
        iconProps: props.iconProps ?? { iconName: 'Send' },
        title: props.title ?? props.text ?? "Betalingsoverblik",
        // disabled: status === 40,//approved
        onClick: () => {
            toggleHideDialog();

        }
        //workflow: props.workflow
        // onClick: onClick
    }, [toggleHideDialog, status]);


    const { data: participants } = queryEntitySWR<tournamentparticipant>(app.getEntityFromKey("Tournament Participant"),
        new ODataBuilder<tournamentparticipant>()
            .filter(`tournamentid eq ${tournamentid} and Purpose/Purpose eq 'Spiller' and status eq 40`)
            .select("id", "playerid", "teamid", "name", "classid")
            .expand("class", c => c.select("name","starttime","id"))
            .expand("team", team => team.select("name", "id"))
            .expandCollection("tournamentparticipantpayments",
                payments => payments
                    .select("id", "paymenttype", "tournamententryfeeid")
                    .expand("tournamententryfee", fee => fee.select("name", "amount", "teamfee", "discountamount"))
                    .expand("transaction")
            )
            .expand("player",
                player => player
                    .select("name")
                    .expandCollection("networkparticipants", networkparticipants => networkparticipants.expand("network", network => network.select("name")))
                    .expandCollection("accountroleassignments", roles => roles
                        .select("accountid")
                        .expand("role", role => role.select("name"))
                        .expand("account",
                            account => account.select("name", "id", "primarycompanycode"))
                    ).expand("primaryemail", contactinfo => contactinfo)
                    .expand("primarymobilephone")
            ).build(), !hideDialog
    );
    const { data: purposes } = queryEntitySWR<TournamentParticipantPurpose>(app.getEntityFromKey("Tournament Participant Purpose"),
        new ODataBuilder<TournamentParticipantPurpose>().build(),
        !hideDialog
    );
    const teams = useMemo(() => groupByTeams(participants?.items ?? []), [participants?.items]);

    //  const accounts = getCommonAccounts(participants);
    const accountDropdownId = useId("dropdown-account");
    //  const feeDropdownId = useId("dropdown-fees");
    //const selection = useControlledDataGridSelection(new Set<string>(participants?.items.map(k => k.id) ?? []));
    //useEffect(() => {
    //    selection.reset(participants?.items.map(k => k.id) ?? []);
    //}, [participants?.items])

    const { mutate: mutateView } = useModelDrivenViewContext();
    const { mutate: mutateForm } = useFormChangeHandlerProvider();
    const mutate = () => { mutateView(); mutateForm(); }
    const [buttonTxt, setButtonTxt] = useState("Godkend");
    const [statusPayload, setStatusPayload] = useState<WorkflowState>();



    const [payload, setPayload] = useState({ rankedin_tournamentid: 0, rankedin_classid: 0 });

    const [editiableRow, setEditiableRow] = useState<string>();

    const onokclick = async () => {
      //  console.log(Array.from(selection.selectedItems));
        if (buttonTxt === "Luk") {
            mutate();
            toggleHideDialog();

            //TODO - mutate is not implemented properly, dirty fix now
            location.reload();

            return;
        }
        toggleIsWorking();

        let { result, rsp } = await runWorkflow("SendToRankedInWorkflow", "Manual",
            {
                ...payload,  
                tournamentid: tournamentid,               
                participants: selectedTeams.map(x => ({
                    teamid: x,
                }))
            },
            {
                currentEntityCollectionSchemaName, currentRecordId, onStatusUpdated: (a) => {
                    console.log("Status Updated: ", a);
                    setStatusPayload(a);
                }, fullStatusPayload: true, refreshInterval: 1000
            });


        if (result.status.toLowerCase() === "failed") {

            return;
        }
        setButtonTxt("Luk");
        toggleIsWorking();


    }



    return (<>

        <SimpleDialog primaryButton={{ disabled: isWorking, children: buttonTxt, onClick: onokclick }} title={`${tournamentname} - Send Besked`} hideDialog={hideDialog} toggleHideDialog={toggleHideDialog}>

            <div style={{ display: "flex", gap: "1rem", flexDirection: "column" }}>

                <Field aria-readonly={isWorking}
                    label="TournamentId:"


                >
                    <Input type="number" defaultValue={payload.rankedin_tournamentid?.toString()} onChange={(e, d) => setPayload(old => ({ ...old, rankedin_tournamentid: parseInt(d.value) }))} />

                </Field>
                <Field aria-readonly={isWorking}
                    label="ClassId:"


                >
                    <Input type="number" defaultValue={payload.rankedin_classid?.toString()} onChange={(e, d) => setPayload(old => ({ ...old, rankedin_classid: parseInt(d.value) }))} />

                </Field>
                <TeamsTable showAllClass={showAllClass} showAll={showAll} setSelectedTeams={setSelectedTeams} teams={teams}  defaultStartTime={tournamentstarttime} />
                 

                {(isWorking || statusPayload?.status === "Succeded") && <ul>
                    {statusPayload?.events?.filter(x => x.eventType === "action_completed").map(evt => <li>{evt.actionKey} {evt.status === "Failed" ? '❌' : '(✔)'} </li>)}
                </ul>}

                {statusPayload?.failedReason && <pre>{statusPayload?.failedReason}</pre>}

                {isWorking && <ProgressBar />}
            </div>

        </SimpleDialog>
    </>
    );
});